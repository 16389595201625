<template>
	<div>
		<datepicker :disabled="disabled" :value="dateOnly" :allow-future-dates="allowFutureDates" @input="updateDate" />
		<timepicker :disabled="disabled" :value="timeOnly" :style="{ marginTop: '2px' }" @input="updateTime" />
	</div>
</template>
<script>
import Datepicker from '@components/DatePicker.vue'
import Timepicker from '@components/TimePicker.vue'

export default {
	name: 'DateTimePicker',
	components: {
		Datepicker,
		Timepicker,
	},
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		allowFutureDates: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateOnly: null,
			timeOnly: null,
		}
	},
	watch: {
		value: {
			handler(val) {
				this.dateOnly = val ? new Date(val) : null
				this.timeOnly = val ? new Date(val) : null
			},
			immediate: true,
		},
	},
	methods: {
		updateDate(value) {
			this.dateOnly = value ? new Date(value) : null
			this.updateValue()
		},
		updateTime(value) {
			this.timeOnly = value ? new Date(value) : null
			this.updateValue()
		},
		updateValue() {
			// Date component is required
			if (!this.dateOnly) {
				this.$emit('input', null)
				return
			}
			// Create a new date
			const value = new Date(this.dateOnly)
			// Set the time if set
			if (this.timeOnly) {
				value.setHours(this.timeOnly.getHours(), this.timeOnly.getMinutes())
			}
			this.$emit('input', value)
		},
	},
}
</script>





















































































































































import { mapState, mapGetters } from 'vuex'
import AstButton from '@components/Button.vue'
import AstInput from '@components/Input.vue'
import BirthdateAgeInputs from '@components/BirthdateAgeInputs.vue'
import DateTimePicker from '@components/DateTimePicker.vue'
import ModalitySelect from '@components/ModalitySelect.vue'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import { openUserStudiesDlg } from '@dialogs/UserStudiesDlg.vue'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { uploadData } from '@services/uploads'
import { ValidatorMixin } from '@mixins/validator'

export default {
	name: 'UploadStudyForm',
	components: {
		AstButton,
		AstInput,
		BirthdateAgeInputs,
		DateTimePicker,
		ModalitySelect,
	},
	mixins: [ValidatorMixin],
	props: {
		context: {
			type: String,
			required: true,
		},
		study: {
			type: Object,
			default: () => ({}),
		},
		isEmbedded: {
			type: Boolean,
			default: false,
		},
		isAttachment: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			uploadData,
			isSubmitting: false,
			form: {
				accessionNumber: '',
				deviceName: '',
				institutionName: '',
				modalityId: null,
				ownerName: '',
				patientBirthDate: undefined,
				patientGender: '',
				patientId: '',
				patientName: '',
				referringPhysiciansName: '',
				studyDateTime: new Date(),
				studyDescription: '',
			},
			genderOptions: ['Male', 'Male Castrated', 'Female', 'Female Spayed'],
		}
	},
	computed: {
		...mapGetters(['isCommunityUser']),
		...mapState({
			// @ts-ignore
			modalityOptions: state => state.static.modalities,
		}),
		pendingCounts() {
			let count = this.uploadData.getPendingUploads().length
			return count + (count > 1 ? ' files' : ' file')
		},
		reservedNameInUse() {
			const reservedNames = ['DELETE', 'TEST', 'DAILYQA', 'DAILY QA', 'TEST MEASURE', 'QA']
			if (reservedNames.includes(this.form.ownerName)) return this.form.ownerName
			if (reservedNames.includes(this.form.patientName)) return this.form.patientName
			return null
		},
		canSubmitForm() {
			if (this.isSubmitting) return false
			if (this.reservedNameInUse) return false
			if (this.anyErrors) return false
			return true
		},
	},
	watch: {
		study: {
			handler(study) {
				if (study) this.copyMetadata(study)
			},
			immediate: true,
		},
		canSubmitForm: {
			handler() {
				this.$emit('validation-change', this.canSubmitForm)
			},
			immediate: true,
		},
		form: {
			handler() {
				this.validator.validate()
				this.$emit('form-change', this.form)
			},
			immediate: true,
			deep: true,
		},
	},
	mounted() {
		this.$el.scrollIntoView()
	},
	methods: {
		async copyFromStudy() {
			const openDialog = this.isCommunityUser ? openUserStudiesDlg : openStudiesDlg
			let study = await openDialog()
			if (!study) return
			// TODO: community/studies could return more data to avoid next request
			if (this.isCommunityUser) study = await this.getUserStudy(study.studyId)
			this.copyMetadata(study)
		},
		copyMetadata(study) {
			if (!study) return
			for (const key in this.form) {
				if (key === 'studyDateTime') continue // do not copy study date
				if (key === 'patientBirthDate') {
					// convert birthdate to a Date
					this.form.patientBirthDate = study.patientBirthDate && new Date(study.patientBirthDate)
					continue
				}
				if (key in study) this.form[key] = study[key]
			}
			if (!this.isAttachment) {
				const modalityOption = this.modalityOptions.find(option => option.name === study.modality)
				if (modalityOption) this.form.modalityId = modalityOption.id
			}
		},
		async getUserStudy(studyId) {
			try {
				const { studies, deletedStudies } = await this.$api.viewer.getStudy({ ids: [studyId] }, false)
				if (studies.length) return studies[0]
				if (deletedStudies.length) {
					showAlert('The selected study was deleted from the source account and is no longer available.')
				} else {
					showAlert('The selected study is no longer available.')
				}
				return null
			} catch (err) {
				return null
			}
		},
		async submit() {
			if (!this.canSubmitForm) return
			this.isSubmitting = true
			if (this.isAttachment) {
				// set attachment-only study's modality to OT
				const otherModality = this.$store.state.static.modalities.find(m => m.name === 'OT')
				this.form.modalityId = otherModality.id
			}
			try {
				await uploadData.startUpload(
					this.context,
					{
						...this.form,
						patientBirthDate: this.createDateString(this.form.patientBirthDate),
					},
					this.isAttachment
				)
			} finally {
				this.isSubmitting = false
			}
		},
		createDateString(date) {
			// create ISO 8601 date string without timezone offset
			if (!date) return
			const dateObject = new Date(date)
			return dateObject.toISOString().replace('Z', '')
		},
		async cancelPendingUpload() {
			if (await showConfirm('Are you sure you want to cancel importing this study?')) {
				uploadData.CANCEL_PENDING_UPLOADS(this.context)
			}
		},
	},
}

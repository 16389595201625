<template>
	<div class="tp-wrapper">
		<svg v-if="!isMobileOS" class="tp-clock" viewBox="0 0 24 24">
			<path
				d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"
			/>
		</svg>
		<input
			v-if="isTimeInputSupported"
			ref="timeInput"
			v-model="time"
			type="time"
			class="time-picker input"
			:disabled="disabled"
			@blur="validateTimeInput"
		/>
		<input
			v-else
			ref="textInput"
			:value="time"
			type="text"
			pattern="[0-2]?[0-9]:[0-5][0-9]\s?(am|AM|pm|PM)?\s?"
			class="time-picker input"
			:placeholder="placeholder"
			:disabled="disabled"
			@blur="validateTextInput"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import isMobileOS from '@utils/isMobileOS'

export default {
	name: 'TimePicker',
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isTimeInputSupported: true,
			is12HourLocale: false,
			isMobileOS: isMobileOS(),
		}
	},
	computed: {
		...mapGetters(['themeIsDark']),
		placeholder() {
			if (this.is12HourLocale) {
				return '--:-- --'
			} else {
				return '--:--'
			}
		},
		time: {
			get() {
				if (!this.value) {
					return ''
				}
				const dte = this.value ? new Date(this.value) : new Date()
				const hours = dte.getHours()
				const minutes = dte.getMinutes()
				const toTwoDigits = num => ('0' + Math.abs(parseInt(num))).slice(-2)
				// if using text input AND if locale uses 12-hour format, then return hh:mm AM/PM string
				if (!this.isTimeInputSupported && this.is12HourLocale) {
					let time = [hours > 12 ? hours - 12 : hours, minutes].map(toTwoDigits).join(':')
					time += hours >= 12 ? ' PM' : ' AM'
					return time
				}
				// otherwise, return 24-hour hh:mm string and let time input localize automatically
				return [hours, minutes].map(toTwoDigits).join(':')
			},
			set(time) {
				// if date is invalid, set model to null
				if (!time || !time.includes(':')) return this.$emit('input', null)
				// update model's hours and minutes
				const dte = this.value ? new Date(this.value) : new Date()
				let [hours, minutes] = time
					.replace(/[a-zA-Z\s]/g, '')
					.split(':')
					.map(Number)
				// time input will already use 0-23 as hours, but text input may not
				if (time.toLowerCase().includes('pm') && hours < 12) hours += 12
				dte.setHours(hours, minutes)
				this.$emit('input', dte)
			},
		},
	},
	mounted() {
		const input = document.createElement('input')
		const value = 'not a valid time'
		input.setAttribute('type', 'time')
		input.setAttribute('value', value)
		this.isTimeInputSupported = input.value !== value
		this.is12HourLocale = new Date(new Date().setHours(23, 0)) // test 11pm locale string
			.toLocaleTimeString(this.getLocale())
			.toLowerCase()
			.includes('pm')
	},
	methods: {
		validateTimeInput() {
			// reset time input if left in an invalid state
			if (!this.$refs.timeInput.checkValidity()) this.time = this.time
		},
		validateTextInput(e) {
			if (this.$refs.textInput.checkValidity()) {
				this.time = e.target.value
			} else {
				this.time = null
			}
		},
	},
}
</script>

<style lang="scss">
.tp-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	input:disabled {
		opacity: 0.7;
	}
}
.time-picker {
	max-height: 36px;
	max-width: 350px;
	padding-left: 25px;
	font-family: sans-serif; // to match our text inputs

	&::-webkit-outer-spin-button,
	&::-webkit-clear-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&::-webkit-calendar-picker-indicator {
		background: none;
	}
	-moz-appearance: textfield; /* Firefox */
}
.tp-clock {
	position: absolute;
	left: 5px;
	width: 16px;
	height: 16px;
	fill: var(--tertiary-label);
	pointer-events: none;
}
</style>
